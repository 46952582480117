.stacking-heading {
  font-weight: 600 !important;
  line-height: 73px !important;
  margin-bottom: 70px;
  margin-top: 61px;
}
// total card
.total-card {
  padding: 93px 10px;
  text-align: center;
  background-color: #e8f0fa;
  background-image: url(../../assets/images/staking/total-bg1.png),
    url(../../assets/images/staking/total-bg1.png);
  background-repeat: no-repeat;
  background-position: bottom left, top right;
  background-size: 150px;
  border-radius: 20px;
  h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    color: #7b3fe4;
    margin-bottom: 20px;
  }
  h5 {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 0;
  }
}

.fw-600 {
  font-weight: 600;
}
.reward-section {
  padding: 110px 0 140px 0;
  a {
    font-weight: 700;
    font-size: 17px;
  }
}
// pool section
.token-pools {
  padding: 110px 0 75px 0;
  background: #edf9ff;
  border-radius: 40px;
  margin: 0 24px;
  .common-heading {
    font-weight: 600;
    font-size: 50px;
    margin-bottom: 60px;
    line-height: 75px;
  }
}
//pool card
.stakig_card_top {
  display: flex;
  align-items: center;
  background: #e7dcfc;
  border-radius: 20px;
  padding: 15px 26px 15px 15px;
  margin-bottom: 15px;
}
.stakig_card_top p {
  margin-bottom: 0;
  margin-left: 20px;
  font-weight: 600;
  font-size: 18px;
  color: #0f2052;
  flex-grow: 1;
}
.stakig_card_top > img {
  height: 40px;
  width: 40px;
}
.stakig_card_top > img:nth-child(2) {
  margin-left: -9px;
}
.stakig_card_bottom {
  padding: 30px 24px 24px 24px;
  background: #7b3fe4 url(../../assets/images/staking/pool-bg.svg) no-repeat
    right top;
  margin-bottom: 35px;
  border-radius: 20px;
}
.stakig_card_bottom .col-6 p {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 27px;
}
.stakig_card_bottom .col-6 span {
  font-size: 14px;
  color: #a6b8ed;
  font-weight: 500;
}

.name_amount_cards {
  padding: 30px;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 27px;
  .btn {
    font-size: 16px;
    font-weight: 600;
    &.unstake {
      color: #e95b5b;
      background: #fadede;
      margin-top: 8px;
    }
  }
}

.name_amount_cards label {
  font-weight: 500;
  font-size: 14px;
}
.name_amount_cards > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name_amount_cards > div input {
  font-size: 26px;
  line-height: 39px;
  font-weight: 700;
  margin-bottom: 0;
  padding-left: 0;
  position: relative;
  width: 170px;
  border: none;
  background: transparent;
  color: #7b3fe4;
  outline: none;
  // &::-webkit-outer-spin-button,
  // &::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  // }
  // &[type=number] {
  //   -moz-appearance: textfield;
  // }
}
.name_amount_cards > div span {
  color: #7b3fe4;
  font-size: 18px;
  font-weight: 500;
}
.stakig_card_bottom .connect_common_btn {
  font-weight: 600;
}
.stakig_card_bottom .red_btn {
  background: #ff3434;
  margin-top: 15px;
  font-weight: 600;
  color: #fff;
}

// NFT Staking popup
.stacking-popup {
  .modal-content {
    background-color: #602dc2;
    background-image: url(../../assets/images/staking/popup-bg.png);
    background-repeat: no-repeat;
    background-position: top right;
    padding: 60px 80px;
    border: none;
    border-radius: 0;
  }
  .modal-header {
    padding: 0;
    border-bottom: 0;
    color: #fff;
    margin-bottom: 40px;
    .btn-close {
      color: #fff;
      background: transparent url(../../assets/images/about/close.svg)
        center/1em auto no-repeat;
      opacity: 1;
    }
  }
}
// slider css
.staking-slider {
  .embla {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }
  .embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
  }
  .embla__viewport.is-dragging {
    cursor: grabbing;
  }
  .embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
  }
  .embla__slide {
    position: relative;
    min-width: 70%;
    padding-left: 10px;
  }
  .embla__slide__inner {
    position: relative;
    overflow: hidden;
  }
  .embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
  }
  .embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
  }
  .embla__button:disabled {
    cursor: default;
    opacity: 0.3;
  }
  .embla__button__svg {
    width: 100%;
    height: 100%;
  }
  .embla__button--prev {
    left: 27px;
  }
  .embla__button--next {
    right: 27px;
  }
}
// staking popup slide card
.staking-popup-card {
  background: #0a0e3b;
  padding: 10px 30px 10px 10px;
  border-radius: 20px;
  img {
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  h5 {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    margin-bottom: 24px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    color: #a6b8ed;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
  }
}
// responsive

// responsive
@media (max-width: 992px) {
  .stacking-heading {
    line-height: 50px !important;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .reward-section {
    padding: 60px 0;
  }
  .token-pools {
    padding: 60px 0;
    margin: 0 5px;
    .common-heading {
      margin-bottom: 40px;
    }
  }
  .name_amount_cards {
    padding: 20px;
  }
  .stacking-popup {
    .modal-dialog {
      min-width: 90%;
    }
    .modal-content {
      padding: 30px 40px;
    }
    .modal-header {
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 776px) {
  .stakig_card_bottom {
    margin-bottom: 50px;
  }
  .token-pools {
    padding: 40px 0 10px 0;
    margin: 0 5px;
  }
  .staking-popup-card {
    padding: 15px;
    img {
      width: 100%;
      height: 250px;
      margin-bottom: 10px;
    }
    h5 {
      margin-bottom: 15px;
    }
  }
}
@media (max-width: 576px) {
  .stacking-heading {
    line-height: 40px !important;
    margin-bottom: 25px;
    margin-top: 35px;
  }
  .total-card {
    padding: 60px 10px;
    h3 {
      font-size: 32px;
      margin-bottom: 10px;
    }
    h5 {
      font-size: 15px;
    }
  }
  .reward-section {
    padding: 40px 0;
  }
  .token-pools {
    padding: 30px 0 10px 0;
    .common-heading {
      font-size: 35px;
      margin-bottom: 15px;
    }
  }
  .name_amount_cards > div p {
    font-size: 30px;
  }
  .name_amount_cards > div input {
    font-size: 22px;
  }
  .name_amount_cards > div span {
    font-size: 15px;
  }
  .stakig_card_bottom {
    padding: 30px 15px 35px 15px;
  }

  .stakig_card_top {
    margin-bottom: 15px;
  }
  .stakig_card_bottom .col-6 p {
    margin-bottom: 15px;
  }
  .stacking-popup {
    .modal-content {
      padding: 20px 20px;
    }
  }
  .staking-popup-card {
    img {
      height: 180px;
    }
  }
  .staking-slider .embla__slide {
    min-width: 90%;
  }
  .stacking-popup {
    .modal-content {
      padding: 10px;
    }
  }
}

.network-info {
  padding: 20px;
  border-radius: 20px;
  background-color: #232539;
  color: #fff;
  margin-bottom: 30px;
}
.tocken_number {
  word-break: break-word;
}
.network-info p {
  margin-bottom: 0;
}

.network-info .btn-link {
  color: white;
  opacity: 0.7;
  font-size: 0.9rem;
}
